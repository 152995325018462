import React from 'react';
import {Title} from "../../../App/Styles/Typography.styled";
import styled from 'styled-components';

const Container = styled.div`
    height: 500px;
    width: 300px;
    overflow-y: auto;
    overflowX: 'hidden', // Отключение горизонтального скролла
    padding: 0;
    margin: 0;
`;

const AboutUsPage = () => {
    return (
        <Container>
            <Title>We love AI</Title>
        </Container>
    );
};

export default AboutUsPage;